<template>
  <ui-select
    class="v3-academic-course-group-by-period-picker"
    :options="options"
    v-model="selectedGroupId"
    @input="$emit('input', selectedGroupId)"
    :placeholder="placeholder"
  ></ui-select>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi';
import academicApi from '@/modules/v3/api/academic';

import { UiSelect } from '@/modules/ui/components';

export default {
  name: 'v3-academic-course-group-by-period-picker',
  mixins: [useApi],
  api: academicApi,

  components: {
    UiSelect
  },

  props: {
    value: {},

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    yearId: {
      type: String,
      required: true
    },

    periodId: {
      type: String,
      required: true,
    },

    query: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      areas: [],
      selectedGroupId: null,
      isLoading: false
    };
  },

  computed: {
    yearAndPeriodAndQuery() {
      return JSON.stringify([this.yearId, this.periodId, this.query]);
    },

    options() {
      let retval = [];

      if (this.areas.length == 0) {
        return [];
      }


      this.areas.forEach(area => {
        area.subjects.forEach(subject => {
          subject.courses.forEach(course => {
            course.groups.forEach(group => {
              retval.push({
                value: group.id,
                text: group.name,
                secondary: subject.name + ' / ' + course.name
              });
            });
          });
        });
      });

      return retval;
    }
  },

  methods: {
    async loadAreas() {
      this.isLoading = true;
      this.areas = await this.$api.getAcademicGroupsByPeriod(this.yearId, this.periodId, this.query);
      this.$emit('fetched', this.areas);
      this.isLoading = false;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedGroupId = newValue;
      }
    },

    yearAndPeriodAndQuery: {
      immediate: true,
      handler() {
        this.loadAreas();
      }
    },

    query: {
      immediate: false,
      deep: true,
      async handler() {
        await this.loadAreas();
      }
    },
  }
};
</script>