<template>
	<div id="schedule">
		<!-- dialogo que muestra información del evento -->
		<ui-dialog v-if="open" :open.sync="open" @close="resetList">
			<template #header>
				<div class="ui-dialog-title">
					<ui-icon 
						v-if="innerOpenStudents || innerOpenTeachers"
						value="mdi:arrow-left" 
						@click="resetListInfo" 
						:title="$t('AcademicSchedule.back')" 
						style="cursor: pointer; color: rgb(24 1 67); margin-right: 10px;"
					>
					</ui-icon>
					{{ title }}
				</div>
				<!-- BEGIN: input de busqueda -->
				<input
					type="search"
					v-if="triggerDay"
					v-model="search"
					:placeholder="$t('AcademicSchedule.filter')"
					class="ui-search inner-search"
					spellcheck="false"
					@keyup.enter="setterm($event)"
					@keyup.esc="search = ''"
				/>
				<p v-html="$t('AcademicSchedule.filter.message', { search: search })" v-if="search.length > 0" class="message-search"></p>
				<div v-if="terms" class="terms">
					<span v-for="(term, key) in terms" :key="key" class="term-items">
						<small style="position: relative">
							{{ term }}
							<div class="closeitem" @click="delterm(term)">x</div>
						</small>
					</span>
				</div>
				<!-- END: input de busqueda -->
			</template>

			<template #contents>
				<!-- BEGIN: contenedor de cards con información del curso -->
				<div v-if="!triggerDay">
					<!-- <Transition name="fade" mode="out-in"> -->
						<span class="container-info" v-if="!innerOpenStudents && !innerOpenTeachers">
							<!-- BEGIN: cards -->
							<div class="card-info" v-for="(item, i) in selectedGroupItems" :key="i" @click="openInfo(item)">
								<span class="principal_1" v-if="item.data.principal" :style="{color: defaultColor}">{{ item.data.principal }}</span>
								<span class="secondary_1" v-if="item.data.secondary">{{ item.data.secondary }}</span>
								<span class="tertiary_1">
									<img 
										:src="item.data.icon" 
										class="tertiary-icon" 
										width="50" 
										height="50" 
										:style="item.data.link != null || item.action == true ? 'cursor: pointer;' : ''"
										@click="goTo(item.data.link)"
									/>
									{{ item.data.tertiary }}
								</span>
							</div>
							<!-- END: cards -->
						</span>

						<!-- BEGIN: información de estudiantes -->
						<div v-else-if="innerOpenStudents">
							<ui-person-item
								v-for="(student, key) in visibleStudents"
								:key="key"
								:person="student"
								:secondary="student.code"
								:tertiary="student.email"
								formal
							>
								<template #right v-if="allowToTakeAttendance">
									<!-- <span style="display:flex; align-items: center; flex-direction: column;">
										<label class="toggler-wrapper style-1" :title="absence[student.id] ? 'presente' : 'ausente'">
											<input type="checkbox" :id="student.id" v-model="absence[student.id]" :checked="absence[student.id]">
												<div class="toggler-slider">
													<div class="toggler-knob"></div>
												</div>
										</label>
										<h5> {{ absence[student.id] ? 'presente' : 'ausente' }} </h5>
									</span> -->
									<span>
										<select :name="'absences_' + student.id" v-model="absence[student.id]">
											<option v-for="(type, i) in absencesTypes" :value="type.id" :key="i">{{type.name}}</option>
										</select>
									</span>
								</template>
							</ui-person-item>
						</div> 
						<!-- END: información de estudiantes -->

						<!-- BEGIN: información de profesores adicionales -->
						<div v-else-if="innerOpenTeachers">
							<ui-person-item
								v-for="(teacher, key) in visibleAdditionalTeachers"
								:key="key"
								:person="teacher.person"
								:secondary="teacher.person.document"
								:tertiary="teacher.person.email"
								formal
							>
								<!-- <template #right></template> -->
							</ui-person-item>
						</div> 
						<!-- END: información de profesores adicionales -->
					<!-- </Transition> -->
				</div>
				<!-- END: contenedor -->

				<!-- Información cuando se da click en el día -->
				<ui-drawer v-for="(event, key) in dinamicinformation" :key="key" v-else>
					<template #trigger>
						<ui-item
							:key="event.datos.time"
							:icon="event.datos.image ? event.datos.image : 'g:group'"
							:text="event.datos.principal"
							:secondary="event.datos.secondary"
							:tertiary="event.datos.tertiary"
							@click-body="event.datos.students != undefined && event.datos.students.length > 0 ? clickEventInfo($event, event) : null"
							@click="$event.preventDefault(); $event.stopPropagation();"
						>
							<template #right>
								<ui-icon
									value="mdi:launch"
									@click="linkGroup($event, event.datos)"
									:title="$t('AcademicSchedule.go.to.group')"
								></ui-icon>
							</template>
						</ui-item>
					</template>
				</ui-drawer>
				<!-- END: Información click en el día -->
			</template>

			<template #footer v-if="dinamicinformation.length == 0 || (innerOpenStudents && allowToTakeAttendance && absencesQ)">
				<div v-if="dinamicinformation.length == 0">
					{{ $t('AcademicSchedule.no.classes.for.today') }}
				</div>
				<div v-else-if="innerOpenStudents && allowToTakeAttendance">
					<input type="button" class="ui-button --main" :value="$t('AcademicSchedule.take.absence')"/> 
					<input type="button" class="ui-button --secondary" :value="$t('AcademicSchedule.cancel')" @click="resetListInfo()"/>
				</div>
			</template>
		</ui-dialog>

		<!-- dialogo para cambio de horarios -->
		<ui-dialog v-if="openNewClass" :open.sync="openNewClass">
			<template #header>
				<div class="ui-dialog-title">
					{{ $t('AcademicSchedule.title.move.session') }}
				</div>
			</template>

			<template #contents>
				<div v-html="$t('AcademicSchedule.move.class') + ' <b>' + newClass.data.eventName + ' </b> ' + $t('AcademicSchedule.from.teacher') + ' <b> ' + newClass.data.eventNameSecondary + ' </b> ' + $t('AcademicSchedule.to.day') + ' <b>' + $t(newClass.dayName)"></div>
				<div style="padding: 20px 0px 5px 0px">
					<fieldset style="margin: 0px !important; padding: 0px">
						<legend class="legend-move-session">
							{{ $t('AcademicSchedule.message.new') }}
						</legend>
						<div id="container">
							<div class="icon">
								<ui-icon value="mdi:calendar-clock" />
							</div>
							<div class="content">
								<div class="hour">
									<div class="label-hour">
										{{ $t('AcademicSchedule.move') }}
										{{ $t('AcademicSchedule.message.class.newStartHour') }}
										<b>{{ getCompleteStartHour }}</b> 
									</div>
									<div class="input-hour">
										<input
											type="time"
											id="newStartTime"
											v-model="newStartTime"
											:min="newClass.hour + ':00'"
											:max="endHour + ':00'"
										/>
									</div>
								</div>
								<div class="hour">
									<div class="label-hour">
										{{ $t('AcademicSchedule.move') }}
										{{ $t('AcademicSchedule.message.class.newEndHour') }}
										<b>{{ getCompleteEndHour }}</b> 
									</div>
									<div class="input-hour">
										<input
											type="time"
											id="endTime"
											v-model="newEndTime"
											:min="newClass.hour + ':00'"
										/>
									</div>
								</div>
							</div>
							<div class="duration">
								<div class="time">{{ duration }}'</div>
							</div>
						</div>
					</fieldset>
				</div>
			</template>

			<template #footer>
				<input
					type="button"
					class="ui-button --main"
					:value="$t('AcademicSchedule.confirm')"
					@click="moveClass()"
				/>
			</template>
		</ui-dialog>

		<!-- BEGIN: componente Calendario -->
		<ui-calendar
			:start-hour="startHour"
			:end-hour="endHour"
			:events="data"
			:hide-label-day="hideLabelDay"
			:week-end="weekEnd"
			:use-background-images="useBackgroundImages"
			:show-current-time="showCurrentTime"
			:default-color="defaultColor"
			:allow-to-delete-classes="allowToDeleteClasses"
			:allow-move-sessions="allowMoveSessions"
			:additional-teachers="additionalTeachers"
			@click-day="clickDay"
			@click-event="clickEvent"
			@delete-event="deleteEvent"
			@new-class-event="newEvent"
		></ui-calendar>
		<!-- END: componente Calendario -->
	</div>
</template>

<script>
import { UiItem, UiDialog, UiDrawer, UiIcon } from '@/modules/ui/components';
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import UiPersonItem from '@/modules/core/components/Person/ListItem.vue';

import { useApi } from '@/modules/api';
import Api from '@/modules/v3/components/Academic/Schedule/api';

import UiCalendar from './AcademicScheduleCalendar.vue';
import iconStudents from './img/students.svg';
import iconClassBook from './img/classbook.svg';
import iconAdditionalTeachers from './img/additionalteachers.svg';
import iconTime from './img/time.svg';
import iconClassLink from './img/classLInk.svg';

export default {
	name: 'schedule',
	components: {
		UiItem,
		UiDrawer,
		UiCalendar,
		UiDialog,
		UiIcon,
		UiPersonItem,
	},
	$api: Api,
	mixins: [useI18n, useApi],

	data() {
		return {
			eventInformation: [],
			originalValue: [],
			open: false,
			openNewClass: false,
			search: '',
			triggerDay: false,
			title: '',
			terms: [],
			newList: null,
			newClass: {},
			newStartTime: '',
			newEndTime: '',
			iconStudents,
			iconClassBook,
			iconClassLink,
			iconAdditionalTeachers,
			iconTime,
			cardGroupItems: [],
			datos: {},
			selectedGroupItems: [],
			visibleStudents: [],
			visibleAdditionalTeachers: [],
			innerOpenStudents: false,
			innerOpenTeachers: false,
			absence: [],
			absencesTypes: [],
			massiveAbsences: [],
			absencesQ: false,
		};
	},

	props: {
		hideLabelDay: {
			type: Boolean,
			required: false,
			default: false,
		},
		data: {
			type: Array,
			required: true,
		},
		startHour: {
			type: Number,
			required: false,
		},
		endHour: {
			type: Number,
			required: false,
		},
		weekEnd: {
			type: Boolean,
			required: false,
		},
		useBackgroundImages: {
			type: Boolean,
			required: false,
			defalt: false,
		},
		showCurrentTime: {
			type: Boolean,
			required: false,
			defalt: false,
		},
		defaultColor: {
			type: String,
			required: false,
		},
		allowToDeleteClasses: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowMoveSessions: {
			type: Boolean,
			required: false,
			default: false,
		},
		additionalTeachers: {
			type: Boolean,
			required: false,
			default: false,
		},
		rol: {
			type: Number,
			required: false,
		},
		allowToTakeAttendance: {
			type: Boolean,
			required: false,
		},
	},

	mounted() {
		this.getAbsencesClassContext();
	},

	watch: {
		dinamicinformation: {
			immediate: false,
			handler: function (newVal) {
				this.newList = newVal;
			},
		},

		terms: {
			immediate: false,
			deep: true,
			handler: function (val) {
				if (val.length === 0) {
					this.eventInformation = this.originalValue;
				}
			},
		},

		absence: {
			handler: function(val) {
				let absenceTypes = Object.entries(val);
				if (absenceTypes.length > 0) {
					this.absencesQ = true;
				} else {
					this.absencesQ = false;
				}
			}
		},
	},

	computed: {
		showCalendar: function () {
			return this.data.length > 0;
		},

		dinamicinformation: function () {
			if (this.search == ' ') {
				return;
			}

			if (this.search.length >= 3) {
				return this.eventInformation.filter((q) => {
					var principal = q.datos.principal.toLowerCase();
					var secondary = q.datos.secondary.toLowerCase();
					var tertiary = q.datos.tertiary.toLowerCase();
					return (
						principal.includes(this.search) ||
						secondary.includes(this.search) ||
						tertiary.includes(this.search)
					);
				});
			}

			return this.eventInformation;
		},

		duration: function () {
			return this.getDuration(this.newStartTime, this.newEndTime);
		},

		getCompleteStartHour: function () {
			return (this.newClass.data.startHour.toString().padStart(2, '0') + ':' + this.newClass.data.startMinutes.toString().padStart(2, '0'));
		},

		getCompleteEndHour: function () {
			return (this.newClass.data.endHour.toString().padStart(2, '0') + ':' + this.newClass.data.endMinutes.toString().padStart(2, '0'));
		},
	},

	methods: {
		openInfo(item) {
			if (item.action && item.data.type == "students" && item.data.students.length > 0) {
				this.innerOpenStudents = true;
			} else if (item.action && item.data.type == "additionalteachers" && item.data.teachers.length > 0) {
				this.innerOpenTeachers = true;
			}
		},

		resetListInfo() {
			this.innerOpenStudents = false;
			this.innerOpenTeachers = false;
			this.absence = [];
		},

		async getAbsencesClassContext() {
			// ausencias a clase, contexto = 2
			await this.$api.getAbsencesClassContext(2).then(response => {
				if (response.length > 0) {
					this.absencesTypes.push({id: null, name: 'Presente', default: true});
					response.forEach(element => {
						this.absencesTypes.push({id: element.id, name: element.name, default: false});
					});
				}
			});
		},

		async getStudents(groupId, periodId, sessionId) {
			let obj = [];
			await this.$api.getStudents(groupId, periodId).then((response) => {
				if (response.length > 0) {
					obj = {
						id: sessionId,
						principal: response.length,
						secondary: '',
						tertiary: this.$t('AcademicSchedule.students'),
						students: response,
						icon: this.iconStudents,
						link: null,
						type: 'students',
					};
					this.visibleStudents = [];
					this.visibleStudents = response;
				}
			});

			return obj;
		},

		async getAdditionalTeachers(groupId, personId, periodId) {
			let obj = [];
			await this.$api
				.getAdditionalTeachersByGroup(groupId, null, periodId, 1)
				.then((response) => {
					obj = {
						id: groupId,
						principal: response.length > 0 ? response.length : '0',
						secondary: '',
						tertiary: this.$t('AcademicSchedule.additional.teachers'),
						teachers: response,
						icon: this.iconAdditionalTeachers,
						link: null,
						type: 'additionalteachers',
					};
					this.visibleAdditionalTeachers = [];
					this.visibleAdditionalTeachers = response;
				});
			return obj;
		},

		getClassBookLink(groupId, periodId, personId, subjectName) {
			const v3Host = this.$httpClient.hosts.v3.host;
			const url = v3Host + '/academic/course/group/classbook?group=' + groupId + '&period=' + periodId + '&person=' + personId;
			return {
				id: groupId,
				principal: '',
				secondary: subjectName,
				tertiary: this.$t('AcademicSchedule.classbook'),
				icon: this.iconClassBook,
				link: url,
				type: 'classbook',
			};
		},

		getClassLink(groupId, periodId, personId, yearId, personType, subjectName, groupName) {
			let url = '';
			const v3Host = this.$httpClient.hosts.v3.host;

			if (personType === 'student') {
				url = v3Host + '/v4/academic/course/group/dashboard?person=' + personId + '&period=' + periodId + '&group=' + groupId;
			} else {
				url = v3Host + '/v4/academic/course/group/manage?year=' + yearId + '&group=' + groupId + '&person=' + personId + '&period=' + periodId;
			}

			return {
				id: groupId,
				principal: '',
				secondary: subjectName,
				tertiary: this.$t('AcademicSchedule.go.to.group') + ": " + groupName,
				icon: this.iconClassLink,
				link: url,
				type: 'classlink',
			};
		},

		getEventInformation(info) {
			return {
				id: info.groupId,
				principal: info.duration,
				secondary: '',
				tertiary: info.classroom ? this.$t('AcademicSchedule.classroom') + ": " + info.classroom : this.$t('AcademicSchedule.no.classroom'),
				icon: this.iconTime,
				link: null,
				type: 'information',
			};
		},

		getClassInfo() {},

		// 1. GoTo Class card
		// 2. Students number card
		// 3. Additional teachers card
		// 4. Information card
		// 5. Classbook card
		async aggregateData(sessionId, groupId, periodId, personId, duration, classroom, subjectName, yearId, personType, groupName) {
			let that = this;

			// 1. GoTo Class card
			let goToClass = this.getClassLink(groupId, periodId, personId, yearId, personType, subjectName, groupName);
			let result2 = that.cardGroupItems.filter(element => element.id == sessionId);

			if (result2.length == 0) {
				that.cardGroupItems.push({ 
					id: sessionId, 
					data: goToClass,
					action: false,
				});
			} else {
				let check = result2.filter(element => element.data.type == 'classlink');
				if (check.length == 0) {
					that.cardGroupItems.push({ 
						id: sessionId, 
						data: goToClass,
						action: false,
					});
				}
			}

			this.selectedGroupItems = this.cardGroupItems.filter(element => element.id == sessionId);

			// 2. Students number card
			if (this.rol == 0 || this.rol == 2) {
				this.getStudents(groupId, periodId, sessionId)
					.then(students => {
						if (students.students.length > 0) {
							let result = that.cardGroupItems.filter(element => element.id == sessionId);
		
							if (result.length == 0 && that.cardGroupItems.length == 0) {
								that.cardGroupItems.push({ 
									id: sessionId,
									data: students,
									action: true,
								});
							} else {
								let check = result.filter(element => element.data.type == 'students');
		
								if (check.length == 0) {
									that.cardGroupItems.push({ 
										id: sessionId, 
										data: students,
										action: true,
									});
								} else {
									check[0].data = students;
								}
							}
							this.selectedGroupItems = this.cardGroupItems.filter(element => element.id == sessionId);
						}
					});
			}

			// 3. Additional teachers card
			if (this.rol == 0 || this.rol == 2) {
				this.getAdditionalTeachers(groupId, personId, periodId)
					.then(additional => {
						let result = that.cardGroupItems.filter(element => element.id == sessionId);
						if (result.length == 0) {
							that.cardGroupItems.push({ 
								id: sessionId, 
								data: additional,
								action: true,
							});
						} else {
							let check = result.filter(element => element.data.type == 'additionalteachers');
							if (check.length == 0) {
								that.cardGroupItems.push({ 
									id: sessionId, 
									data: additional,
									action: true,
								});
							} else {
								// En caso de encontrar la info, actualizarlo
								check[0].data = additional;
							}
						}
						this.selectedGroupItems = this.cardGroupItems.filter(element => element.id == sessionId);
					});
			}

			// 4. Information card
			let eventInformation = this.getEventInformation({
				groupId: groupId,
				duration: this.getDuration(duration.start, duration.end) + "'",
				classroom: classroom
			});

			let result1 = that.cardGroupItems.filter(element => element.id == sessionId);

			if (result1.length == 0) {
				that.cardGroupItems.push({ 
					id: sessionId, 
					data: eventInformation,
					action: false,
				});
			} else {
				let check = result1.filter(element => element.data.type == 'information');
				if (check.length == 0) {
					that.cardGroupItems.push({ 
						id: sessionId, 
						data: eventInformation,
						action: false,
					});
				}
			}

			this.selectedGroupItems = this.cardGroupItems.filter(element => element.id == sessionId);

			// 5. Classbook card
			let classBook = this.getClassBookLink(groupId, periodId, personId, subjectName);
			let result = that.cardGroupItems.filter(element => element.id == sessionId);

			if (result.length == 0) {
				that.cardGroupItems.push({ 
					id: sessionId, 
					data: classBook,
					action: false,
				});
			} else {
				let check = result.filter(element => element.data.type == 'classbook');
				if (check.length == 0) {
					that.cardGroupItems.push({ 
						id: sessionId, 
						data: classBook,
						action: false,
					});
				}
			}

			this.selectedGroupItems = this.cardGroupItems.filter(element => element.id == sessionId);
		},

		goTo(url) {
			if (url != undefined && url != null && url != '') {
				window.open(url);
			}
		},

		getDuration(startHour, endHour) {
			if (!startHour || !endHour) return 0;

			let startTime = startHour.split(':');
			let endTime = endHour.split(':');

			let sTime =
				new Date(1970, 1, 1, startTime[0], startTime[1]).getTime() / 1000;
			let eTime = new Date(1970, 1, 1, endTime[0], endTime[1]).getTime() / 1000;

			return (eTime - sTime) / 60;
		},

		getFinishTime(startHour, duration) {
			if (!startHour || !duration) return 0;

			let startTime = startHour.split(':');
			let eTime = new Date(
				'1970-01-01T' + startTime[0] + ':' + startTime[1] + ':00'
			).getTime();

			return new Date(eTime + duration * 60000);
		},

		setterm() {
			if (this.dinamicinformation.length == 0) {
				return;
			}

			if (this.terms !== undefined && this.terms.length === 0) {
				this.originalValue = this.eventInformation;
			}

			this.terms.push(this.search);
			this.search = '';
			this.eventInformation = this.newList;
		},

		delterm(term) {
			let result = [];

			var filter_array = this.terms.filter((element) => {
				if (element != term) {
					return true;
				}
			});

			this.terms = filter_array;

			filter_array.forEach((element) => {
				result = this.originalValue.filter((q) => {
					var principal = q.datos.principal.toLowerCase();
					var secondary = q.datos.secondary.toLowerCase();
					var tertiary = q.datos.tertiary.toLowerCase();
					return (
						principal.includes(element) ||
						secondary.includes(element) ||
						tertiary.includes(element)
					);
				});
			});

			this.eventInformation = result;
		},

		resetList() {
			this.innerOpenStudents = false;
			this.innerOpenTeachers = false;
			this.eventInformation = this.originalValue;
			this.terms = [];
			this.search = '';
		},

		clickDay(events) {
			this.eventInformation = [];
			this.triggerDay = true;

			events.events.forEach((event) => {
				let startClass = event.start.split(' ')[1].split('-');
				let endClass = event.end.split(' ')[1].split('-');

				let information = {
					principal: event.title,
					secondary: event.titleSecondary,
					tertiary: this.$t('AcademicSchedule.start.class.at') + ' ' + startClass + ' ' + this.$t('AcademicSchedule.ends.class.at') + ' ' + endClass,
					image: event.image,
					students: event.students,
					classroom: event.classroom,
					group: event.group ? event.group.id : null,
					period: event.period ? event.period : null,
					person: event.person ? event.person : event.group.teacher.id,
					personType: event.personType ? event.personType : 'teacher',
					year: event.year,
				};

				this.eventInformation.push({ datos: information });
			});

			const days = [
				'sunday',
				'monday',
				'tuesday',
				'wednesday',
				'thursday',
				'friday',
				'saturday',
			];

			const temp = new Date(events.day.date);
			this.title = this.$t('AcademicSchedule.classes.for.today') + ': ' + this.$t('AcademicSchedule.day.' + days[temp.getDay()]) + ' ' + temp.getDate();
			this.open = true;
			this.$emit('click-day', events);
		},

		clickEvent(event) {
			let Minutos = event.endMinutes - event.startMinutes;
			let Horas = event.endHour - event.startHour;

			this.triggerDay = false;

			if (Minutos < 0) {
				Horas--;
				Minutos = 60 + Minutos;
			}

			let retHoras = Horas.toString();
			let retMinutos = Minutos.toString();
			let duration = retHoras.toString().padStart(2, '0') + ':' + retMinutos.toString().padStart(2, '0');
			let principalMessage = '';
			let teacherName = '';

			let startEventTime = event.startHour.toString().padStart(2, '0') + ":" + event.startMinutes.toString().padStart(2, '0');
			let endEventTime = event.endHour.toString().padStart(2, '0') + ":" + event.endMinutes.toString().padStart(2, '0');

			if (event.students != undefined) {
				let countStudents = event.students.length;
				principalMessage = event.eventNameSecondary + ' ( ' + countStudents + ' ' + this.$t('AcademicSchedule.students') + ' )';
			} else {
				teacherName = event.group.teacher.firstname + ' ' + event.group.teacher.lastname;
				principalMessage = event.group.name + ': ' + teacherName;
			}

			let information = {
				principal: principalMessage,
				secondary: this.$t('AcademicSchedule.start.class.at') + ' ' + event.startHour.toString().padStart(2, '0') + ':' + event.startMinutes.toString().padStart(2, '0') + ' ' + this.$t('AcademicSchedule.ends.class.at') + ' ' + event.endHour.toString().padStart(2, '0') + ':' + event.endMinutes.toString().padStart(2, '0'),
				tertiary: this.$t('AcademicSchedule.duration') + ': ' + duration + ' ' + this.$t('AcademicSchedule.hours'),
				image: event.eventImage,
				classroom: event.classroom || null,
				group: event.group ? event.group.id : null,
				period: event.period || event.period.id,
				person: event.person ? event.person : event.group.teacher.id,
				personType: event.personType ? event.personType : 'teacher',
				year: event.year,
			};

			this.eventInformation = [];
			this.eventInformation.push({ datos: information });
			this.title = event.eventName;
			this.open = true;
			this.$emit('click-event', event);

			this.datos = event;

			// Obtengo la información del grupo para las cards del contenedor
			this.aggregateData(
				event.sessionId, 
				event.group.id, 
				event.period.id ? event.period.id : event.period, 
				event.group.teacher.id, 
				{start: startEventTime, end: endEventTime}, 
				information.classroom, 
				event.group.course.subject.name,
				event.year,
				this.rol == 1 || this.rol == 3 ? "student" : "colaborator",
				event.group.name,
			);
		},

		clickEventInfo(evt, event) {
			evt.stopPropagation();
			evt.preventDefault();

			if (event.datos.group && event.datos.period) {
				this.$emit('click-event-info', {
					group: event.datos.group,
					period: event.datos.period,
					person: event.datos.person,
					personType: event.datos.personType,
				});
			}
		},

		deleteEvent(event) {
			this.$emit('delete-class-room', event);
		},

		newEvent(event) {
			const classEvent = event;
			classEvent.data = JSON.parse(classEvent.data);
			this.newClass = classEvent;
			this.newStartTime = this.newClass.hour + ':00';
			this.newClass.sourceDuration = this.getDuration(
				classEvent.data.initialStartClass,
				classEvent.data.initialEndClass
			);
			let tempFinishTime = this.getFinishTime(
				this.newStartTime,
				this.newClass.sourceDuration
			);
			this.newEndTime =
				tempFinishTime.getHours().toString().padStart(2, '0') +
				':' +
				tempFinishTime.getMinutes().toString().padStart(2, '0');
			this.openNewClass = true;
		},

		moveClass() {
			// mover: this.newClass
			this.newClass.targetHour = this.newStartTime;
			this.newClass.finishHour = this.newEndTime;
			this.openNewClass = !this.openNewClass;
			this.$emit('move-class', this.newClass);
		},

		linkGroup(evt, data) {
			evt.preventDefault();
			evt.stopPropagation(); 
		
			let url = '';
			const v3Host = this.$httpClient.hosts.v3.host;

			let group = data.group.id != null ? data.group.id : data.group;

			if (data.personType === 'student') {
				url = v3Host + '/v4/academic/course/group/dashboard?person=' + data.person + '&period=' + data.period + '&group=' + group;
			} else {
				url = v3Host + '/v4/academic/course/group/manage?year=' + data.year + '&group=' + group + '&person=' + (data.group?.teacher?.id == null ? data.person : data.group.teacher.id) + '&period=' + data.period.id;
			}

			window.open(url);
		},

		mousemove(evt) {
			evt.target.style.transform = "translateZ(1000px)";
		},

		mouseleave(evt) {
			evt.target.style.transform = "translateZ(0px)";
		},
	},

	i18n: {
		es: {
			'AcademicSchedule.start.class.at': 'La clase inicia a las',
			'AcademicSchedule.ends.class.at': 'finaliza a las',
			'AcademicSchedule.classes.for.today': 'Clases para hoy',
			'AcademicSchedule.no.classes.for.today': 'No hay clases para hoy 😎',
			'AcademicSchedule.students': 'estudiantes',
			'AcademicSchedule.duration': 'Duración',
			'AcademicSchedule.hours': 'horas',
			'AcademicSchedule.day.sunday': 'Domingo',
			'AcademicSchedule.day.monday': 'Lunes',
			'AcademicSchedule.day.tuesday': 'Martes',
			'AcademicSchedule.day.wednesday': 'Miercoles',
			'AcademicSchedule.day.thursday': 'Jueves',
			'AcademicSchedule.day.friday': 'Viernes',
			'AcademicSchedule.day.saturday': 'Sábado',
			'AcademicSchedule.filter': 'filtrar datos',
			'AcademicSchedule.filter.message': 'Pulse \'intro\' para filtrar por <bold>{{search}}</bold> o escape para limpiar',
			'AcademicSchedule.message.new': 'Nueva hora',
			'AcademicSchedule.title.move.session': 'Actualizar sesión',
			'AcademicSchedule.message.class.newStartHour': 'inicio de clase',
			'AcademicSchedule.message.class.newEndHour': 'fin de clase',
			'AcademicSchedule.message.class.newDuration': 'Duración',
			'AcademicSchedule.additional.teachers': 'Profesores adicionales',
			'AcademicSchedule.classbook': 'Libro de clase',
			'AcademicSchedule.no.classroom': 'Sin aula',
			'AcademicSchedule.classroom': 'Aula',
			'AcademicSchedule.go.to.group': 'Ir al grupo',
			'AcademicSchedule.back': 'regresar',
			'AcademicSchedule.move.class': 'Mover clase',
			'AcademicSchedule.move': 'mover',
			'AcademicSchedule.confirm': 'confirmar',
			'AcademicSchedule.from.teacher': 'del docente',
			'AcademicSchedule.to.day': 'al día',
			'AcademicSchedule.Monday': 'Lunes',
			'AcademicSchedule.Tuesday': 'Martes',
			'AcademicSchedule.Wednesday': 'Miercoles',
			'AcademicSchedule.Thursday': 'Jueves',
			'AcademicSchedule.Friday': 'Viernes',
			'AcademicSchedule.Saturday': 'Sábado',
			'AcademicSchedule.Sunday': 'Domingo',
			'AcademicSchedule.take.absence': 'registrar',
			'AcademicSchedule.cancel': 'cancelar',
		},
		en: {
			'AcademicSchedule.start.class.at': 'Class begin at',
			'AcademicSchedule.ends.class.at': 'ends at',
			'AcademicSchedule.classes.for.today': 'Classes for today',
			'AcademicSchedule.no.classes.for.today': 'No classes for today 😎',
			'AcademicSchedule.students': 'students',
			'AcademicSchedule.duration': 'Duration',
			'AcademicSchedule.hours': 'hours',
			'AcademicSchedule.day.sunday': 'Sunday',
			'AcademicSchedule.day.monday': 'Monday',
			'AcademicSchedule.day.tuesday': 'Tuesday',
			'AcademicSchedule.day.wednesday': 'Wednesday',
			'AcademicSchedule.day.thursday': 'Thursday',
			'AcademicSchedule.day.friday': 'Friday',
			'AcademicSchedule.day.saturday': 'Saturday',
			'AcademicSchedule.filter': 'filter',
			'AcademicSchedule.filter.message': 'Press \'enter\' to filter by <b>{{search}}</b> or escape to clean',
			'AcademicSchedule.message.new': 'New session',
			'AcademicSchedule.title.move.session': 'Move session',
			'AcademicSchedule.message.class.newStartHour': 'starting at',
			'AcademicSchedule.message.class.newEndHour': 'ending at',
			'AcademicSchedule.message.class.newDuration': 'duration',
			'AcademicSchedule.additional.teachers': 'Additional teachers',
			'AcademicSchedule.classbook': 'Classbook',
			'AcademicSchedule.no.classroom': 'no classroom',
			'AcademicSchedule.classroom': 'Classroom',
			'AcademicSchedule.go.to.group': 'go to the group',
			'AcademicSchedule.back': 'back',
			'AcademicSchedule.move.class': 'Move class',
			'AcademicSchedule.move': 'move',
			'AcademicSchedule.confirm': 'confirm',
			'AcademicSchedule.from.teacher': 'of the teacher',
			'AcademicSchedule.to.day': 'to day',
			'AcademicSchedule.Monday': 'Monday',
			'AcademicSchedule.Tuesday': 'Tuesday',
			'AcademicSchedule.Wednesday': 'Wednesday',
			'AcademicSchedule.Thursday': 'Thursday',
			'AcademicSchedule.Friday': 'Friday',
			'AcademicSchedule.Saturday': 'Saturday',
			'AcademicSchedule.Sunday': 'Sunday',
			'AcademicSchedule.take.absence': 'register',
			'AcademicSchedule.cancel': 'cancel',
		},
		de: {
			'AcademicSchedule.start.class.at': 'Kursbeginn um',
			'AcademicSchedule.ends.class.at': 'endet am',
			'AcademicSchedule.classes.for.today': 'Kurse für heute',
			'AcademicSchedule.no.classes.for.today': 'Heute kein Unterricht 😎',
			'AcademicSchedule.students': 'Studenten | Studentinnen',
			'AcademicSchedule.duration': 'Dauer',
			'AcademicSchedule.hours': 'Std',
			'AcademicSchedule.day.sunday': 'Sonntag',
			'AcademicSchedule.day.monday': 'Montag',
			'AcademicSchedule.day.tuesday': 'Dienstag',
			'AcademicSchedule.day.wednesday': 'Mittwoch',
			'AcademicSchedule.day.thursday': 'Donnerstag',
			'AcademicSchedule.day.friday': 'Freitag',
			'AcademicSchedule.day.saturday': 'Samstag',
			'AcademicSchedule.filter': 'Filter',
			'AcademicSchedule.filter.message': 'Drücken Sie die Eingabetaste, um nach {{search}} zu filtern, oder die Escape-Taste, um sie zurückzusetzen',
			'AcademicSchedule.message.new': 'Neue Sitzung',
			'AcademicSchedule.title.move.session': 'Sitzung verschieben',
			'AcademicSchedule.message.class.newStartHour': 'beginnt um',
			'AcademicSchedule.message.class.newEndHour': 'endet bei',
			'AcademicSchedule.message.class.newDuration': 'SSitzungsdauer',
			'AcademicSchedule.additional.teachers': 'zusätzliche Lehrer',
			'AcademicSchedule.classbook': 'Klassenbuch',
			'AcademicSchedule.no.classroom': 'kein Klassenzimmer',
			'AcademicSchedule.classroom': 'Raum',
			'AcademicSchedule.go.to.group': 'zur Gruppe gehen',
			'AcademicSchedule.back': 'zurück',
			'AcademicSchedule.move.class': 'Klasse bewegen',
			'AcademicSchedule.move': 'umziehen',
			'AcademicSchedule.confirm': 'bestätigen',
			'AcademicSchedule.from.teacher': 'des Lehrers',
			'AcademicSchedule.to.day': 'zu Tag',
			'AcademicSchedule.Monday': 'Montag',
			'AcademicSchedule.Tuesday': 'Dienstag',
			'AcademicSchedule.Wednesday': 'Mittwoch',
			'AcademicSchedule.Thursday': 'Donnerstag',
			'AcademicSchedule.Friday': 'Freitag',
			'AcademicSchedule.Saturday': 'Samstag',
			'AcademicSchedule.Sunday': 'Sonntag',
			'AcademicSchedule.take.absence': 'registrieren',
			'AcademicSchedule.cancel': 'kündigen',
		},
	},
};
</script>

<style lang="scss">
.ui-dialog .ui-dialog-container {
	font-family: var(--ui-font-secondary) !important;
	max-width: 500px !important;
	position: absolute;
	top: 10% !important;
	width: 500px !important;
	/* transition: all 200ms ease-in; */
}

.v3-academic-options {
	padding: 10px;
	margin: 5px;
}

.empty {
	padding: 10px 0px 5px 0px;
}

.ui-icon-image {
	border-radius: 10px !important;
}

.row {
	justify-content: space-around;
}

.ui-dialog-title {
	font-size: 1rem;
	font-weight: bold;
	padding: var(--ui-padding);
}

input.ui-native {
	width: 100%;
}

.terms {
	padding: var(--ui-padding);
	padding-top: 12px !important;
	padding-bottom: 5px;
}

.term-items {
	background-color: #daeeff;
	border-radius: 8px;
	color: #0462a2;
	margin-right: 5px;
	padding: 3px 8px 5px 8px;
	font-size: 0.85em;
}

.closeitem {
	background-color: #f7fbff;
	border: 1px solid #0462a2;
	border-radius: 5px;
	color: #0462a2;
	cursor: pointer;
	font-weight: bold;
	font-size: 11px;
	height: 15px;
	position: absolute;
	right: -15px;
	text-align: center;
	top: -12px;
	width: 15px;
}

.ui-search {
	margin: 0px 20px;
	padding: 12px;
	font-size: 1em;
	color: rgba(0, 0, 0, 0.8);
	outline-color: #1d7cbf;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-top: 1px solid rgba(0, 0, 0, 0.26);
	border-radius: var(--ui-radius);
	color: #666;
	background-color: #fcfcfc;
}

.inner-search {
	margin-left: 20px !important;
}

.message-search {
	padding: 0px 24px;
	font-size: 0.85em;
}

#container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 5px;

	> .icon {
		width: 5%;
		text-align: center;
	}

	> .content {
		width: 85%;
		font-size: 0.9rem;

		> .hour {
			display: flex;
			justify-content: space-between;
			padding: 5px;
		}
	}

	> .duration {
		display: block;
		width: 10%;

		> .time {
			text-align: center;
			font-size: 1.5em;
		}
	}
}

fieldset {
	border: 1px solid #ecdcdc;
	border-radius: 5px;
	padding-top: 5px;
}

input[type='time'] {
	font-family: var(--ui-font-secondary);
	font-size: 1em;
	margin-top: -5px;
	font-weight: 500;
}

.legend-move-session {
	position: relative;
	margin: 0px 10px;
	font-weight: 600;
	font-size: 0.9rem;
}

.ui-dialog .ui-dialog-container .ui-dialog-title {
	display: flex;
	justify-content: flex-start;
	/* transition: all 200ms ease-in; */
}

@keyframes fadeIn {
	0% {
		opacity: 0; 
	}
	100% {
		opacity: 1;
	}
}

/* cards de información de los grupos */
.container-info {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	justify-content: center;
	padding-bottom: 10px;
	animation: fadeIn .25s;

	.card-info {
		display: flex;
		align-items: center;
		width: 40%;
		background-color: rgb(255, 255, 255);
		padding: 10px;
		border-radius: 15px;
		border: 1px solid rgb(236, 236, 236);
		box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 20px 0px;
		height: 200px;
		text-align: center;
		flex-wrap: wrap;
		transform-style: preserve-3d;

		.principal_1 {
			font-size: 45px;
			display: inline-block;
			text-align: center;
			width: 100%;
			min-height: 33%;
			max-height: 100%;
		}

		.secondary_1 {
			width: 100%;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
			color: grey;
			min-height: 33%;
			max-height: 66%;
		}

		.tertiary_1 {
			width: 100%;
			display: grid;
			font-size: 13px;
			align-items: center;
			justify-content: center;
			justify-items: center;
			min-height: 33%;
			max-height: 66%;
		}
	}

	.card-info:hover {
		box-shadow: rgba(116, 167, 255, 0.4) 0px 2px 20px 0px;
		border: 1px solid #95bdff;
	}
}

@media (max-width: 800px) {
	.ui-dialog .ui-dialog-container {
		max-width: 400px !important;
		left: 0;
		right: 0;
		margin: auto;
		bottom: auto !important;
		width: 400px !important;
	}
}

#icon-link:hover {
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	color: red !important;
	background: rgba(255, 185, 185, 0.05);
	border-radius: 50%;
}

.fade-enter-active{ 
	transition: opacity 0.25s ease;
}

.fade-leave-active {
	transition: opacity 0.25s ease;
}

.fade-leave,
.fade-enter-to { 
	opacity: 1 
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

/*-- Estilos checkbox -- */ 
.toggler-wrapper {
	display: block;
	width: 23px;
	height: 13px;
	cursor: pointer;
	position: relative;
}

.toggler-wrapper input[type="checkbox"] {
	display: none;
}

.toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
	background-color: rgb(5, 105, 182);
}

.toggler-wrapper .toggler-slider {
	background-color: #ccc;
	position: absolute;
	border-radius: 100px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.toggler-wrapper .toggler-knob {
	position: absolute;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.toggler-wrapper.style-1 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
	left: calc(100% - 11px);
}

.toggler-wrapper.style-1 .toggler-knob {
	width: calc(13px - 4px);
	height: calc(13px - 4px);
	border-radius: 50%;
	left: 2px;
	top: 2px;
	background-color: #fff;
}
</style>