<template>
  <div class="ui-tabs">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-tabs',

  props: {
    value: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      selectedTabIndex: -1,
    };
  },

  watch: {
    value: {
      // Este handler no se puede poner "immediate" porque se ejecutaria antes de que exista this.$children
      handler(newValue) {
        this.selectTab(this.$children.find((t) => t.value == newValue));
      },
    },
  },

  mounted() {
    let foundTab = this.$children.find((t) => t.value == this.value);
    this.selectTab(foundTab);
  },

  methods: {
    selectTab(incomingTab) {
      if (!incomingTab) {
        return;
      }

      let incomingTabIndex = this.$children.indexOf(incomingTab);
      if (incomingTabIndex == this.selectedTabIndex) {
        return;
      }

      let direction = incomingTabIndex - this.selectedTabIndex;

      if (this.selectedTabIndex !== -1) {
        this.$children[this.selectedTabIndex].$el.style.setProperty(
          '--tab-anchor',
          direction > 0 ? 'right' : 'left'
        );

        this.$children[this.selectedTabIndex].$el.style.setProperty(
          '--ui-duration-snap',
          '0.14s'
        );
      }

      incomingTab.$el.style.setProperty(
        '--tab-anchor',
        direction > 0 ? 'left' : 'right'
      );

      incomingTab.$el.style.setProperty('--ui-duration-snap', '0.2s');
      incomingTab.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

      this.selectedTabIndex = incomingTabIndex;
      this.$emit('input', incomingTab.value);
    },
  },
};
</script>

<style lang="scss">
.ui-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .ui-tab {
    flex: 1;
    max-width: 256px;
  }

  @media (pointer: coarse) {
    /* on touch devices do not show scrollbar but keep functionality */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none;
    // overflow: -moz-scrollbars-none;
  }

  /*firefox only*/
  scrollbar-width: thin;
  // scrollbar-color: #8c8c8c #e0e0e0;

  /*webkit based browsers only*/
  &::-webkit-scrollbar {
    height: 8px;
  }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  // }

  &::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
