<template>
	<div class="v3-academic-period-schedule-picker">
		<ui-select
			v-if="view == 'select'"
			v-model="selectedPeriodId"
			:multiple="multiple"
			:options="options"
			@change="selectPeriodId(selectedPeriodId)"
			v-bind="$attrs"
			:disabled="disabled || !options.length"
		></ui-select>
		<select
			v-if="view == 'native'"
			v-model="selectedPeriodId"
			:multiple="multiple"
			@change="selectPeriodId(selectedPeriodId)"
			v-bind="$attrs"
			:disabled="disabled || !options.length"
		>
			<optgroup
				v-for="category in categories"
				:key="category.id"
				:label="category.name"
			>
				<option
					v-for="period in category.periods"
					:key="period.id"
					:value="period.id"
					v-text="period.name"
				></option>
			</optgroup>
		</select>
		<ui-tabs
			v-if="view == 'tabs'"
			v-model="selectedPeriodId"
			@change="selectPeriodId($event)"
		>
			<ui-tab
				v-for="(option, i) in options"
				:key="i"
				:value="option.value"
				:label="option.text"
			></ui-tab>
		</ui-tabs>
	</div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi';
import apiAcademic from '@/modules/v3/api/academic';

import { UiSelect, UiTabs, UiTab } from '@/modules/ui/components';

export default {
	name: 'v3-academic-period-schedule-picker',
	mixins: [useApi],
	api: apiAcademic,

	components: {
		UiSelect,
		UiTabs,
		UiTab,
	},

	props: {
		value: {},

		yearId: {
			type: [String, Number],
			required: true,
		},

		multiple: {
			type: Boolean,
			default: false,
		},

		query: {
			type: Object,
			required: false,
			default: null,
		},

		view: {
			type: String,
			required: false,
			default: 'select',
			validator: (v) => ['select', 'native', 'tabs'].includes(v),
		},

		autoSelect: {
			type: Boolean,
			required: false,
			default: false,
		},

		preserve: {
			type: Boolean,
			default: false,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			categories: [],
			selectedPeriodId: null,
		};
	},

	watch: {
		value: {
			immediate: true,
			handler() {
				this.selectedPeriodId = this.value || undefined;
			},
		},

		yearAndQuery: {
			immediate: true,
			async handler(newValue) {
				await this.loadCategories();

				if (this.preserve && this.selectSavedPeriod()) {
					return;
				}

				if (this.autoSelect) {
					this.selectCurrentPeriod();
				}
			},
		},
	},

	computed: {
		yearAndQuery() {
			return JSON.stringify([this.yearId, this.query]);
		},

		options() {
			let periods = [];

			if (this.categories.length == 0) {
				return [];
			}

			this.categories.forEach((category) => {
				periods = periods.concat(
					category.periods.map((period) => ({
						value: period.id,
						text: period.name,
						secondary: category.name,
					}))
				);
			});

			return periods;
		},
	},

	methods: {
		selectPeriodId(periodId) {
			localStorage.setItem('v3.period.picker.value', periodId);
			this.$emit('input', periodId);
		},

		selectSavedPeriod() {
			let storedValue = localStorage.getItem('v3.period.picker.value');
			if (storedValue) {
				this.selectPeriodId(storedValue);
				return true;
			}

			return false;
		},

		selectCurrentPeriod() {
			if (!this.categories.length) {
				this.selectedPeriodId = null;
				return;
			}

			let now = Math.floor(new Date().getTime() / 1000);
			let currentPeriod = this.categories[0].periods.find(
				(p) => p.start_date < now && now < p.end_date
			);
			if (!currentPeriod) {
				currentPeriod = this.categories[0].periods[0];
			}

			this.selectPeriodId(currentPeriod.id);
		},

		async loadCategories() {
			this.categories = await this.$api.getAcademicPeriodCategoriesSchedule(
				this.yearId,
				this.query
			);

			this.$emit('fetched', this.categories);
		},
	},
};
</script>
