var studentsCache = {};
var additionalTeachersCache = {};

export default (httpClient) => ({
	async getSchedule(data) {
		return httpClient.get(`/v3/academic/schedule`, {
			yearId: data.yearId,
			periodId: data.periodId,
		});
	},

	async getScheduleByCourseSection(courseId, data) {
		return httpClient.get(`/v3/academic/course/${courseId}/schedule`, {
			yearId: data.yearId,
			periodId: data.periodId,
			teacherId: data.teacherId ? data.teacherId : null,
			optionalSubjects: data.optionalSubjects,
		});
	},

	async getScheduleByCourse(courseId, data) {
		return httpClient.get(`/v3/course/schedule`, {
			yearId: data.yearId,
			courseId: courseId,
			periodId: data.periodId,
			teacherId: data.teacherId ? data.teacherId : null,
			optionalSubjects: false,
		});
	},

	async getScheduleByGroup(groupId, data) {
		return httpClient.get(`v3/academic/course/group/${groupId}/schedule`, {
			yearId: data.yearId,
			periodId: data.periodId,
			teacherId: data.teacherId ? data.teacherId : null,
			optionalSubjects: data.optionalSubjects,
		});
	},

	async getScheduleByTeacher(personId, data) {
		return httpClient.get(`v3/people/${personId}/academic/course/schedule`, {
			yearId: data.yearId,
			periodId: data.periodId,
			optionalSubjects: data.optionalSubjects,
		});
	},

	async getScheduleByStudent(personId, data) {
		return httpClient.get(`v3/people/${personId}/academic/schedule`, {
			yearId: data.yearId,
			periodId: data.periodId,
			optionalSubjects: data.optionalSubjects,
		});
	},

	async getGroupDetails(groupId) {
		return httpClient.get(`/v3/academic/groups/${groupId}`);
	},

	async getCourseSectionDetails(courseSectionId) {
		return httpClient.get(`/v3/course/sections/${courseSectionId}/details`);
	},

	async getCourseDetails(courseSectionId) {
		return httpClient.get(`/v3/course/${courseSectionId}/details`);
	},

	async getPersonDetail(personId) {
		return httpClient.get(`/people/${personId}`);
	},

	async deleleteScheduleClass(groupId, periodId, data) {
		return httpClient.delete(
			`/v3/academic/course/group/${groupId}/sessions/${periodId}`,
			{
				startHour: data.startHour,
				endHour: data.endHour,
				classroom: data.classroom,
				weekday: data.weekday,
				sessionId: data.sessionId,
			}
		);
	},

	async updateScheduleSession(session) {
		const groupId = session.data.group.id;
		const periodId = session.data.period.id;

		return httpClient.put(`/v3/academic/course/group/${groupId}/sessions/${periodId}`,
			{
				sourceDay: session.data.key == '0' ? 7 : session.data.key,
				targetDay: session.day == '0' ? 7 : session.day,
				startHour: session.targetHour,
				endHour: session.finishHour,
				sourceStartHour: session.data.initialStartClass,
				sourceEndHour: session.data.initialEndClass,
				sessionId: session.data.sessionId,
			}
		);
	},

	async getAdditionalTeachersByGroup(groupId, personId = null, periodId, q = null) {
		/* const cacheIndex = `cache_${groupId}_${personId}_${periodId}`; */
		/* if (typeof additionalTeachersCache[cacheIndex] == 'undefined') { */
		return httpClient.get(`/v3/academic/course/group/teachers?group=${groupId}&person=${personId}&period=${periodId}&q=${q}`);
			/* console.log(additionalTeachersCache[cacheIndex])
		}
		return additionalTeachersCache[cacheIndex]; */
	},

	async getStudents(groupId, periodId) {
		/* const cacheIndex = `cache_${groupId}_${periodId}`; */
		/* if (typeof studentsCache[cacheIndex] == 'undefined') { */
		return httpClient.get(`/v3/academic/groups/${groupId}/periods/${periodId}/people`);
		/* } */
		/* return studentsCache[cacheIndex]; */
	},

	async getAbsencesClassContext(context) {
		return httpClient.get(`/v3/attendance/absencetypes?context=${context}`);
	},
	async postAttendanceAbsenceSession(data, sessionId) {
		return httpClient.post(`/v3/attendance/absences/sessions/${sessionId}`, {
				absences: data
			}
		);
	}
});